import { request } from "@/utils/request"

// 商品查询
export const getProductList = async () => {
  return await request.get("/rechargeablecard-api/getProductList")
}

// 提交支付
export const postPayment = async (data: any) => {
  return await request.post("/rechargeablecard-api/payment", data)
}

// 发送验证码消息：
export const postSendVerification = async (data: any) => {
  return await request.post("/rechargeablecard-api/sendVerification", data)
}

// 注册账号：
//  {"realName":"","password":"12345678","phone":"13732000902","email":"test@mail.com","verificationCode":"636508"}
export const postRegister = async (data: any) => {
  return await request.post("/rechargeablecard-api/register", data)
}

// 登录账号：
// {"password":"12345678","username":"13732000902"}
export const postLogin = async (data: any) => {
  return await request.post("/rechargeablecard-api/login", data)
}
export const postLoginPhone = async (data: any) => {
  return await request.post("/rechargeablecard-api/loginByPhone", data)
}
// 获取历史记录：
//  {"size":10,"index":1,"token":""}
export const postOrderHistory = async (data: any) => {
  return await request.post("/rechargeablecard-api/orderHistory", data)
}

// 获取登录用户信息：
export const getLoginUserInfo = async (data: any) => {
  return await request.get("/rechargeablecard-api/getLoginUserInfo", data)
}

/**
 * 登出:
 * params: {
 *  token
 * }
 */
export const getLogout = async (token: any) => {
  return await request.get(`/rechargeablecard-api/logout?token=${token}`)
}
