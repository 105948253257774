import { defineStore } from "pinia"
export const useUserStore = defineStore({
  id: "user",
  state: () => {
    return {
      userInfo: {
        username: "",
      },
      token: "",
    }
  },
  actions: {
    setUserInfo(data: any) {
      console.log(data)
      this.token = data.token
      this.userInfo = {
        username: data.username,
      }
      window.localStorage.setItem("token", this.token)
      window.localStorage.setItem("userInfo", JSON.stringify(this.userInfo))
    },
    clearUserInfo() {
      this.token = ""
      this.userInfo = {
        username: "",
      }
      window.localStorage.clear()
    },
    getUserInfo() {
      const token = window.localStorage.getItem("token") || ""
      const userInfo = JSON.parse(String(window.localStorage.getItem("userInfo")))
      this.token = token
      this.userInfo = userInfo
    },
  },
})
