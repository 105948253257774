import axios from "axios"
import { ElNotification } from "element-plus"

const baseUrl = "https://gv.coinbac.id/"

const request = axios.create({
  baseURL: baseUrl,
})
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    console.log("response:", response)
    if (response.data.code !== '000') {
      ElNotification({
        title: "Error",
        message: response.data.message,
        type: "error",
        duration: 2000,
      })
    }
    return response
  },
  function (error) {
    console.log("error", error)
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error)
  }
)

export { request }
