import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/detail/:name",
    name: "Detail",
    component: () => import("../views/detail.vue"),
  },
  {
    path: "/order",
    name: "Order",
    component: () => import("../views/order.vue"),
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/h5/account.vue"),
  },
  {
    path: "/phone",
    name: "Phone",
    component: () => import("../views/h5/phone.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/h5/register.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
