import { getProductList } from "@/api"
import { defineStore } from "pinia"
export const useDataStore = defineStore({
  id: "data",
  state: () => {
    return {
      list: {},
      banner: [
        require("@/assets/banner/f9f1f1b25eb944ce67f80400ff01b8a.png"),
        require("@/assets/banner/2184748ab5afb94d4f1bdd76c3876b9.png"),
        require("@/assets/banner/df145dc17f950dc3febca19f65b0e3d.png"),
      ],
    }
  },
  actions: {
    async getList() {
      const { data } = await getProductList()
      let obj: any = {}
      data.productList.forEach((item: any) => {
        obj[item.productID] = item
      })
      this.list = obj
    },
  },
})
